body {
  margin: 0;
  padding: 0;
}

/* .preview > video {
  width: 320px;
  height: 240px;
  border: 1px solid #000;
  border-radius: 5px;
} */
video {
  width: 320px;
  height: 240px;
  border: 1px solid #000;
  border-radius: 5px;
}
